class Translation {

    translation = {}

    onDemandTranslation = null

    activeLanguage = 'en-GB'

    languages = LANGUAGES

    activeLanguage = ACTIVE_LANGUAGE

    constructor() {
        this.loadTranslation()
    }

    loadTranslation() {
        const inlineJson = document.querySelector("script[data-translation-json]")

        if(inlineJson) {
            return this.translation = JSON.parse(JSON.parse(inlineJson.textContent))
        }

        throw new Error(`TRANSLATION constant not found in a global scope.`)
    }

    /**
     * @param {string} value
     */
    get(value) {
        if (this.translation) {
            if (value in this.translation) {
                return this.translation[value]
            }

            return value
        }

        throw new Error(`Translation object has not been loaded.`)
    }

    /**
     * @param {string} value
     * @param {string} lang
     */
    getOnDemand(value, lang = "en-GB") {
        var regex = /^(?:[A-Za-z0-9+/]{4})*?(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
        if (this.onDemandTranslation) {
            if (lang in this.onDemandTranslation) {
                if (value in this.onDemandTranslation[lang]) {
                    let s = this.onDemandTranslation[lang][value];
                    if( regex.test(s) ){
                        return atob(s);
                    } else {
                        return s;
                    }
                }
            }
        }

        if (this.translation) {
            if (value in this.translation) {
                let s = this.translation[value];
                if( regex.test(s) ){
                    return atob(s);
                } else {
                    return s;
                }
            }
        }

        return value
    }

    /**
     * @returns {string}
     */
    getActiveLanguage() {
        return this.activeLanguage
    }

    /**
     * @param {string} locale
     * @returns
     */
    getLanguageLabel(locale) {
        return this.languages[locale]
    }

    async loadOnDemandTranslations() {
        this.onDemandTranslation = await $.ajax(`/a/translationOnDemand`)
    }

    /**
     * Fancy sprintf to perform string replacment
     * with freely defined placeholders
     *
     * NOTE: All placeholders have to be in the folowing format:
     * [[ * ]]
     * [[ meaningful_note_of_subject_variable_to_give_translator_a_context ]]
     *
     * Fe:
     * `Welcome, [[ user_name ]]`
     * `[[ number_of_mailboxes ]] x [[ size_in_megabytes ]] Mailboxes`
     *
     * Usage:
     * translation.sprintf(
     *  `[[ number_of_mailboxes ]] x [[ size_in_megabytes ]] Mailboxes`,
     *  ['150', '2000MB']
     * )
     */
    sprintf(subject, [...args]) {
        const pattern = /\[\[(.*?)\]\]/g
        const templates = []

        let match
        while((match = pattern.exec(subject)) != null ) {
            templates.push(match[0])
        }

        if(templates.length) {
            templates.forEach((t, i) => {
                if(typeof [...args][i] !== 'undefined') {
                    subject = subject.replace(t, [...args][i])
                }
            })
        }

        return subject
    }
}

const translation = new Translation()

/** debug to remove */
const updateLanguageSettings = lang => {
    $.ajax({
        method: 'post',
        url: `/a/changeLanguage`,
        contentType: 'application/json',
        data: JSON.stringify({
            language: lang
        }),
    }).done(() => {
        window.location.reload()
    }).fail(() => {
        ShowNotification.fail(translation.get('preferences_save_fail'))
    })
}
/** debug to remove */
